<template>
  <div class="page">
    <div class="mod">
      <p class="warn">{{tips}}</p>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/utils'

export default {
  name: '404',
  data () {
    return {
      time: 3,
      tips: '你想访问的页面不存在.. 3秒后返回首页'
    }
  },
  mounted () {
    this.timer()
  },
  methods: {
    timer () {
      if (this.time > 0) {
        this.time--
        this.tips = '你想访问的页面不存在... ' + this.time + '秒后返回首页'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.tips = ''
        Utils.go_to_index()
      }
    }
  }
}
</script>
